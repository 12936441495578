<template>
  <div>
    <banner :path="bannerPath"></banner>
    <div class="cloud-wrap">
      <div class="cloud1">
        <p>以开放、共享、安全为目标，融合业务，为客户营销与天游线路检测中心-欢迎光临体验赋能。</p>
        <img src="~@/assets/images/cloud/cloud-01.jpg" />
      </div>
      <div class="cloud2">
        <p>金融级天游线路检测中心-欢迎光临经过了大量银行案例实践，丰富的经验助力开放、融合，小也是一种力量，我们共同探索全新业态。</p>
        <!-- <img src="~@/assets/images/cloud/cloud-02.jpg" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { banner } from '@/components'
import bannerPath from '@/assets/images/cloud/banner.jpg'
export default {
  components: {
    banner
  },
  data () {
    return {
      bannerPath
    }
  },
  mounted () {


  },
  methods: {

  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .cloud-wrap{
    width: 1000px;
    margin: 0 auto;
    padding: 60px 0 80px;
    .cloud1{
      @extend %c;
      margin-bottom: 100px;
      p{
        float: left;
        width: 380px;
      }
      img{
        @extend %fr;
      }
    }
    // .cloud2{
    //   @extend %c;
    //   p{
    //     @extend %fr;
    //   }
    //   img{
    //     @extend %fl;
    //   }
    // }
    p{
      line-height: 28px;
      font-size: 16px;
      color: #848484;
      text-align: justify;
      margin-top: 100px;
    }
  }

  @media screen and (max-width:1200px) {
    .cloud-wrap{
      width: auto;
      padding: px2rem(30px) px2rem(15px) px2rem(40px);
      .cloud1{
        margin-bottom: px2rem(50px);
      }
      .cloud1,.cloud2{
        p,img{
          width: auto;
          float: none;
        }
        img{
          display: block;
          width: 90%;
          margin: 0 auto;
        }
      }
      p{
        width: auto;
        line-height: px2rem(20px);
        font-size: px2rem(13px);
        text-indent: 2em;
        margin: 0 auto px2rem(30px);
      }
    }
  }
</style>
